<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col
                            cols="12"
                            
                        >
                            <b-dropdown
                                v-if="iCan( 'tasks', 'write' ) && userType !== 'Freelancer'"
                                id="add-new-dropdown"
                                variant="primary"
                                class="float-right"
                                right
                            >
                                <template #button-content>
                                    <feather-icon icon="PlusCircleIcon" />
                                    {{ i18nT(`Add new`) }}
                                </template>

                                <b-dropdown-item :to="{ name: 'create-task' }">
                                    <div class="text-primary" >
                                        <font-awesome-icon :icon="['fas', 'check-circle']" />
                                        {{ i18nT(`Create task`) }}
                                    </div>
                                </b-dropdown-item>

                                <b-dropdown-item 
                                v-b-modal.modal-use-template
                                >
                                    <div class="text-primary">
                                        <font-awesome-icon :icon="['fas', 'tasks']" />
                                        {{ i18nT(`Use template`) }}
                                    </div>
                                </b-dropdown-item>

                                <!-- <b-dropdown-item 
                                    @click="exportTable"

                                > -->
                                <b-dropdown-item 
                                    v-b-modal.modal-export
                                    :disabled="totalTasks == 0"
                                >
                                    <div :class="{'text-primary': totalTasks !== 0}">
                                        <font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'file-export']" />
                                        {{ i18nT(`Export`) }}
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>

                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Type`)"
                                label-for="taskType"
                            >
                                <v-select
                                    id="taskType"
                                    v-model="taskType"
                                    :options="taskTypes"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Group`)"
                                label-for="taskGroup"
                            >
                                <v-select
                                    id="taskGroup"
                                    v-model="taskGroup"
                                    :options="taskGroups"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && !isTemplate && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Assigned to`)"
                                label-for="assignee"
                            >
                                <v-select
                                    id="assignee"
                                    v-model="assignedTo"
                                    :options="assigneeFilter"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="!isTemplate && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Status`)"
                                label-for="status"
                            >
                                <v-select
                                    id="status"
                                    v-model="status"
                                    :options="statusFilters"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Software`)"
                                label-for="software"
                            >
                                <v-select
                                    id="software"
                                    v-model="software"
                                    :options="softwareFilter"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                :text="i18nT(`Actions`)"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('tasks', 'write') && userType !== 'Freelancer'"
                            >
                                <b-dropdown-item @click="exportSelectedItems">
                                    <span>
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'file-export']" />
                                        {{ i18nT(`Export`) }}
                                    </span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refTaskListTable"
                        :items="fetchTasks"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(actions)="data">
                            <a
                                class="task btn btn-icon rounded-circle btn-gradient-primary mr-1"
                                style="position: relative; left: -15px;"
                                v-b-tooltip.hover.right="'Complete task'"
                                @click.prevent="completeTask(data.item)"
                                v-if="data.item.Completed === 0"
                                :class="{'btn-gradient-secondary': completeBtnClicked && selectedTask.Id == data.item.Id, 'btn-gradient-primary': !completeBtnClicked }"
                            >
                                <feather-icon
                                    :badge="data.item.ShowNotification > 0 ? '1' : ''"
                                    badge-classes="left-10 bg-danger"
                                    icon="CheckIcon"
                                    color="white"
                                />
                            </a>
                            <a
                                class="task btn btn-icon rounded-circle btn-outline-primary task mr-1"
                                style="position: relative; left: -15px;"
                                v-b-tooltip.hover.right="'Reopen task'"
                                @click.prevent="completeTask(data.item)"
                                v-if="data.item.Completed === 1 && userType !== 'Freelancer'"
                                :class="{'btn-outline-secondary': completeBtnClicked && selectedTask.Id == data.item.Id, 'btn-outline-primary': !completeBtnClicked }"
                            >
                                <feather-icon
                                    :badge="data.item.ShowNotification > 0 ? '1' : ''"
                                    badge-classes="left-10 bg-danger"
                                    icon="RefreshCcwIcon"
                                    color="primary"
                                />
                            </a>
                        </template>

                        <template #cell(name)="data">
                            <b-link
                                :to="{ name: 'task-view', params: { id: data.item.Id }}"
                                class="font-weight-bold"
                            >
                                {{ data.item.Label }}
                            </b-link>
                        </template>

                        <template #cell(type)="data">
                            {{ getTypeName(data.item.Type) }}
                        </template>

                        <template #cell(assigned_to)="data">
                            <b-list-group-item
                                class="p-0 border-0"
                                v-if="data.item.AssignedUser"
                            >
                                <b-avatar
                                    class="mr-1"
                                    size="35"
                                    v-b-tooltip.hover="data.item.AssignedUser.FirstName + ' ' + data.item.AssignedUser.LastName"
                                    :text="avatarText(data.item.AssignedUser.FirstName + ' ' + data.item.AssignedUser.LastName)"
                                    :src="data.item.AssignedUser.ImageUrl"
                                    variant="light-primary"
                                >
                                </b-avatar>
                                <!-- <span class="align-text-bottom line-height-1">{{ data.item.AssignedUser.FirstName + ' ' + data.item.AssignedUser.LastName }}</span> -->
                            </b-list-group-item>
                        </template>

                        <template #cell(group)="data">
                            {{ Object.values(data.item.TaskGroups).join(", ") }}
                            <!-- <div v-html="data.item.TaskGroups" /> -->
                        </template>

                        <template  #cell(due)="data">
                            <span v-if="data.item.TaskDueDate">
                            {{ data.item.TaskDueDate | date }}
                            {{ data.item.TaskRepeated }}
                            </span>
                            <span v-else >{{ data.item.TaskRepeated }}</span>
                        </template>

                        <template #cell(created)="data">
                            {{ data.item.CreatedAt | date }}
                        </template>

                        <template #cell(status)="data">
                            <a
                                class="badge badge-pill badge-light-danger"
                                v-if="data.item.TaskIsLate && data.item.Completed == 0"
                            >
                                {{i18nT(`Late`)}}
                            </a>
                            <a
                                :class="data.item.Completed == 0 ? 'badge badge-pill badge-light-success': 'badge badge-pill badge-light-secondary'"
                                v-else
                            >
                                {{ getStatusName(data.item.Completed) }}
                            </a>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="userType !== 'Freelancer' && iCan('tasks', 'write')"
                                                 :to="{ name: 'edit-tasks', params: { id: data.item.Id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="{ name: 'task-view', params: { id: data.item.Id } }">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="userType !== 'Freelancer' && iCan('tasks', 'write')"
                                                 @click="onCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="data.item.Completed !== 1 && iCan('tasks', 'write')"
                                                 @click="completeTask(data.item)">
                                    <feather-icon icon="CheckIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Complete`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="userType !== 'Freelancer' && data.item.Completed === 1 && iCan('tasks', 'write')"
                                    @click="completeTask(data.item)">
                                    <feather-icon icon="RefreshCcwIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Reopen`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="userType !== 'Freelancer' && iCan('tasks', 'write')"
                                                 @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>
                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="12"
                                    md="4"
                                    v-for="task in tasks"
                                    :key="task.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07);">
                                        <b-card-body class="p-0">
                                            <div class="media">
                                                <div class="media-aside align-self-center">
                                                  <span
                                                      class="task btn btn-icon rounded-circle btn-gradient-primary mr-1"
                                                      v-b-tooltip.hover.right="'Complete task'"
                                                      @click.prevent="completeTask(task)"
                                                      v-if="task.Completed === 0"
                                                  >
                                                    <feather-icon
                                                        icon="CheckIcon"
                                                        color="white"
                                                    />
                                                  </span>
                                                    <a
                                                        class="task btn btn-icon rounded-circle btn-outline-primary task mr-1"
                                                        v-b-tooltip.hover.right="'Reopen task'"
                                                        @click.prevent="completeTask(task)"
                                                        v-if="task.Completed === 1"
                                                    >
                                                        <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            color="primary"
                                                        />
                                                    </a>
                                                </div>
                                                <div class="media-body">
                                                    <h4 class="mb-0">
                                                        <a>{{ task.Label }}</a>
                                                    </h4>
                                                    <p class="mb-0">{{ task.TaskType }}</p>
                                                </div>
                                            </div>
                                            <p
                                                class="my-1"
                                                v-html="task.Description"
                                            ></p>
                                            <div class="mt-1">
                                                <h6 class="section-label">
                                                    {{ i18nT(`Created`) }}
                                                </h6>
                                                <div>
                                                    {{ task.CreatedAt | date }}
                                                </div>
                                            </div>

                                            <div class="mt-1">
                                                <h6 class="section-label">
                                                    {{ i18nT(`Last update`) }}
                                                </h6>
                                                <div>
                                                    {{ task.UpdatedAt | date }}
                                                </div>
                                            </div>

                                            <div class="mt-1">
                                                <h6 class="section-label">
                                                    {{ i18nT(`Due date`) }}
                                                </h6>
                                                <div>
                                                    {{ task.TaskDueDate ? task.TaskDueDate : '' }}
                                                </div>
                                            </div>

                                            <div
                                                v-if="task.AssignedTo"
                                                class="mt-1"
                                            >
                                                <h6 class="section-label">
                                                    {{ i18nT(`Assigned to`) }}
                                                </h6>
                                                <b-list-group-item class="p-0 border-0">
                                                    <b-avatar
                                                        class="mr-1"
                                                        size="32"
                                                        :text="avatarText(task.AssignedUser.FirstName + ' ' + task.AssignedUser.LastName)"
                                                        :src="task.AssignedUser.ImageUrl"
                                                        variant="light-success"
                                                    >
                                                    </b-avatar>
                                                    <span class="align-text-bottom line-height-1">{{
                                                            task.AssignedUser.FirstName + ' ' + task.AssignedUser.LastName
                                                        }}</span>
                                                </b-list-group-item>
                                            </div>
                                            <div>
                                            </div>

                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 mt-2 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                class="float-right"
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                right
                                            >

                                                <template #button-content>
                                                    <feather-icon
                                                        icon="MoreVerticalIcon"
                                                        size="16"
                                                        class="align-middle"
                                                    />
                                                </template>
                                                <b-dropdown-item :to="{ name: 'edit-tasks', params: { id: task.Id } }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item :to="{ name: 'task-view', params: { id: task.Id } }">
                                                    <feather-icon icon="EyeIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({item: task})">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({item: task})">
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalTasks"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>

        <b-modal
            id="modal-use-template"
            :title="i18nT(`Task templates`)"
            size="lg"
            :ok-title="i18nT(`Use template`)"
            ok-only
            @ok="useTemplate"
        >
            <h4>{{i18nT(`Templates`)}}</h4>
            
                <b-form-group
                    label-for="templateName"
                    class="required"
                >
                        <v-select 
                            id="templateOptions"
                            v-model="selectedTemplate"
                            :options="templateOptions"
                            label="text"
                        />
                </b-form-group>
        </b-modal>

        <modal-export module="tasks" :allFilters="allFilters"/>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    VBToggle,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTaskList from './useTaskList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'
import ModalExport from '@/views/common/ModalExport/ModalExport.vue'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BAvatar,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput,
        ModalExport
    },
    data() {
        const displayExtentControls =
            useAuth.getCurrentSoftware() != 'ca' &&
            useAuth.getCurrentSoftware() != 'em' &&
            this.iCan('tasks', 'write')
        return {
            userType: useAuth.getUserType(),
            taskTypes: [],
            taskGroups: [],
            assigneeFilter: [],
            statusFilters: [],
            softwareFilter: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            isTemplate: router.currentRoute.meta.template === 1,
            completeBtnClicked: false,
            selectedTask: {},
            avalableFields: [
                {label: this.i18nT(`Type`), key: 'type', visible: true},
                {label: this.i18nT(`Created`), key: 'created', visible: true},
                {label: this.i18nT(`Due`), key: 'due', visible: true},
                {label: this.i18nT(`Assigned to`), key: 'assigned_to', visible: true},
            ],
            templateOptions: [],
            selectedTemplate: null,
            softwarePath: useAuth.getCurrentSoftware(),
            controlVisible: true,
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle
    },
    created() {


        let storePreferences = useAuth.getColumnPreferences('tasks')
        if(storePreferences) {
          this.avalableFields = storePreferences
        }

        this.$http.get(`tasks/taskTypes`).then(({data}) => {
            const types = data.data.map(type => ({
                value: type.Id,
                text: type.Label
            }))

            types.unshift({
                value: -1,
                text: this.i18nT(`All types`)
            })

            this.taskTypes = types
        })

        this.$http.get(`tasks?templates=1&show_per_page=150`).then(({data}) => {
            this.templateOptions = data.data.tasks.map(task => {
                return {
                    value: task.Id,
                    text: task.Label
                }
            })
        })

        if (this.userType !== 'Freelancer') {
            this.$http.get(`tasks/taskGroupsFilter`).then(({data}) => {
                const taskGroups = dictToSelectArray(data.data)

                taskGroups.unshift({
                    value: -1,
                    text: this.i18nT(`All groups`)
                })

                this.taskGroups = taskGroups
            })

            this.$http.get(`tasks/assignedUsersFilter`).then(({data}) => {
                this.assigneeFilter = dictToSelectArray(data.data)
            })
        }

        this.$http.get(`tasks/taskStatuses`).then(({data}) => {
            this.statusFilters = dictToSelectArray(data.data)
            this.status =
                router.currentRoute.meta.template === 1
                    ? {value: -1, text: this.i18nT(`All`)}
                    : {value: 0, text: this.i18nT(`Active`)}
        })

        this.$http.get(`system/softwares`).then(({data}) => {
            const softwareFilter = dictToSelectArray(data.data)

            softwareFilter.unshift({
                value: '99',
                text: this.i18nT(`All`)
            })

            this.softwareFilter = softwareFilter
        })

        this.fetchTasks(null, this.determineVisibility);

    },
    methods: {
        useTemplate() {
            if(this.selectedTemplate && this.selectedTemplate.value) {
                this.$router.push({path: '/'+ this.softwarePath +'/create-task/' + this.selectedTemplate.value})
            }
        },
        exportSelectedItems() {
            this.$http.get(`tasks?id=${this.selectedItems.join(',')}&export=1`)
            .then(({data})=> {
				const url = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'tasks.csv');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

            })
            .catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(error),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
            }
        },
        getStatusName(statusId) {
            const status = this.statusFilters.find(type => type.value == statusId)
            return status ? status.text : ''
        },
        getTypeName(typeId) {
            const type = this.taskTypes.find(type => type.value == typeId)
            return type ? type.text : ''
        },
        getAssigneeName(assigneeId) {
            try {
                if (this.assigneeFilter.length === 0 || assigneeId == null) {
                    return ''
                }

                return this.assigneeFilter.filter(
                    assignee => assignee.value === assigneeId + ''
                )[0].text
            } catch (ex) {
                return ''
            }
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(task) {
            this.$http.post(`tasks/copy?task_id=${task.item.Id}`).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Task Copied`,
                        icon: 'InfoIcon',
                        variant: 'success'
                    }
                })
                this.refetchData()
            })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`tasks?id=${task.item.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The task has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        completeTask(task) {
            this.completeBtnClicked = !this.completeBtnClicked

            this.selectedTask = task
            setTimeout(() => {
                if (!this.completeBtnClicked) return
                const formData = new FormData()
                formData.append('id', task.Id)
                formData.append('completed', 1 - task.Completed)
                this.$http
                    .post('tasks/completedStatus', formData)
                    .then(() => {
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Status changed`,
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                        this.refetchData()
                        this.completeBtnClicked = false
                    })
                    .catch(() => {
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Failed to change status`,
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                        this.refetchData()
                    })
            }, 2000)
        },
        onBulkCopy() {
            this.$http
                .post(`tasks/copy?task_id=${this.selectedItems.join(',')}`)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Task(s) Copied`,
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })

                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`tasks?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The task has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const statusOptions = [
            'Downloaded',
            'Draft',
            'Paid',
            'Partial Payment',
            'Past Due'
        ]


        const {
            fetchTasks,
            tableColumns,
            perPage,
            currentPage,
            totalTasks,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            statusFilter,
            taskType,
            taskGroup,
            assignedTo,
            status,
            software,
            tasks,
            refetchData
        } = useTaskList(router.currentRoute.meta.template === 1)

        return {
            fetchTasks,
            tableColumns,
            perPage,
            currentPage,
            totalTasks,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTaskListTable,
            statusFilter,
            statusOptions,
            taskType,
            taskGroup,
            assignedTo,
            status,
            software,
            avatarText,
            tasks,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
            if(this.userType !== 'Freelancer'){
                return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                    + this.i18nT(`Here, you can create and manage your tasks. No tasks have been created yet.`) + "</h4>"
                    +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/"+ this.softwarePath +"/create-task\"'>" + this.i18nT(`Create task`) + "</button>"
            }
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                + this.i18nT(`No matching records found`) + "</h4>"

        },
        computedColumns() {
            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('tasks', this.avalableFields);

            return col
        },
        allFilters() {
            const params = {
                export: 1,
                sort_order: this.isSortDirDesc ? 'DESC' : 'ASC',
                search: this.searchQuery,
                sort_by: this.sortBy,
            }

            if (this.isTemplate) {
                params.templates =  1
            }

            if (this.taskType.value != -1) {
                params['filters[type]'] = this.taskType.value;
            }
            if (this.taskGroup.value != -1) {
                params['filters[group]'] = this.taskGroup.value;
            }
            if (this.assignedTo.value != '' && this.assignedTo.value != 0) {
                params['filters[assigned_to]'] = this.assignedTo.value;
            }
            if (this.status.value != -1) {
                params['filters[status]'] = this.status.value;
            }
            if (this.software.value != -1) {
                params['filters[software]'] = this.software.value;
            }

            return params
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
